Vue.mixin({
  data: () => {
    return {
      server : {
        get : (uri, showLoading = true) => {
          return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest()
    
            xhr.onload = function () {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(JSON.parse(xhr.response))
                if (showLoading) { store.commit('decRunnnigQueries') }
              } else {
                resolve(xhr.status)
              }
            }
    
            xhr.open('GET', uri)
            if (showLoading) { store.commit('incRunnnigQueries') }
            xhr.send()
          })
        },
        post : (uri, data, showLoading = true) => {
          return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest()
    
            xhr.onload = function () {
              if (xhr.status >= 200 && xhr.status < 300) {
                // console.log('xhr.response :', xhr.response);
                resolve(JSON.parse(xhr.response))
                if (showLoading) { store.commit('decRunnnigQueries') }
              } else {
                resolve(xhr.status)
              }
            }
    
            xhr.open('POST', uri)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
            if (showLoading) { store.commit('incRunnnigQueries') }
            xhr.send(JSON.stringify(data))
          })
        }
      }
      
    }
  },
  computed: {
    encodedPath: function () { return this.$route.fullPath.replace(/\//g, '_') }
  },
  methods: {
    formatDate: function (string) {
      if (string) {
        let dateElements = string.substring(0,10).split('-')
        return dateElements[2] + '/' + dateElements[1] + '/' + dateElements[0]
      } else {
        return null;
      }
    },
    createNewElement: function (formname) {
      return new Promise((resolve, reject) => {
        this.server.get('/get/newelement/' + formname, false).then((data) => {
          this.$router.push({ 
            name: 'elementForm',
            params: { formname: formname, elementid: data }
          })
          resolve(data)
          
        })
      })
    }
  }
})