
let routes = [
  { path: '/login/:returnpath', component: pageLogin, name: 'pageLogin' },
  { path: '/formslist', component: formsList, name: 'formsList' },
  { path: '/:form', component: elementsList, name: 'elementsList' },
  {
    path: '/:formname/config',
    component: configForm,
    name: 'configForm'
  },
  {
    path: '/:formname/:elementid',
    component: elementForm,
    name: 'elementForm'
  },
  {
    path: '/:formname/mass/:idselements',
    component: elementsMassForm,
    name: 'elementsMassForm'
  },
  { path: '*', redirect: '/formslist' }
]

let router = new VueRouter({ 
  routes: routes,
  mode: 'history'
})

document.addEventListener("DOMContentLoaded", () => {
  new Vue({ 
    router,
    store,
    mounted: function () {
      this.server.get('/check-sso').then((data) => {
        store.commit('setUserInfos', data)
      })
    }
  }).$mount('#app')
})
