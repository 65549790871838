let store = new Vuex.Store ({
  state : {
    runningQueries: 0,
    userInfos: { connected: false },
    dataUpdated: 0,
    modal: {
      visible: false,
      message: 'Message Modal !',
      title: 'Titre de la modale',
      info: false,
      okCallback: null
    }
  },
  mutations: {
    incRunnnigQueries: (state) => { state.runningQueries ++ },
    decRunnnigQueries: (state) => { state.runningQueries -- },
    hideModal: (state) => { state.modal.visible = false },
    setModal: (state, modal) => { 
      for (const [key, value] of Object.entries(modal)) {
        state.modal[key] = value
      }
    },
    setUserInfos: (state, userInfos) => {
      for (var key in userInfos) {
        if (userInfos.hasOwnProperty(key)) {
          state.userInfos[key] = userInfos[key]
        }
      }
    },
    setDataUpdated: (state) => { state.dataUpdated ++ }
  },
  actions: {
  }
})